import React from "react"
import containerStyles from "./container.module.css"
import { css } from "@emotion/core"
import { useStaticQuery, Link, graphql } from "gatsby"
import { rhythm } from "../utils/typography"

export default function Container({ children }) {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  return (
    <div className={containerStyles.container}
    css={css`
    margin: 0 auto;
    max-width: 700px;
    align: center;
    `}>
    <h1
    css={css`
          display: block;
          font-style: strong;
          color: silver;
          width: 700px
          align: center;
          white-space: pre-line; 
        `}> {data.site.siteMetadata.title} 
        </h1>
    {children}
  </div>
  )
}

      /*  
    <Link to={`/`}>
      <h3
        css={css`
          margin-bottom: ${rhythm(2)};
          display: inline-block;
          font-style: normal;
          color: black;
          width: 150px
        `}
      >
        Home
      </h3>
    </Link>
    */